<template>
    <div>
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <div class="d-flex align-center pt-3 flex-md-wrap col-12">
                    <v-text-field
                        :placeholder="$t('message.no')"
                        :value="searchValues.invoice"
                        autocomplete="new-password"
                        class="filter-width-200 force-text-left"
                        clearable
                        dense
                        hide-details="auto"
                        append-icon="search"
                        solo
                        @change="searchValues.invoice = $event"
                        @click:append="searchLocalSalesInvoices('invoice')"
                        @click:clear="resetSearch('invoice')"
                        @keyup.enter.prevent="searchLocalSalesInvoices('invoice')"
                    />
                    <v-text-field
                        :placeholder="$t('message.salesOrder')"
                        :value="searchValues.salesOrder"
                        autocomplete="new-password"
                        class="filter-width-200 force-text-left ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        append-icon="search"
                        solo
                        @change="searchValues.salesOrder = $event"
                        @click:append="searchLocalSalesInvoices('salesOrder')"
                        @click:clear="resetSearch('salesOrder')"
                        @keyup.enter.prevent="searchLocalSalesInvoices('salesOrder')"
                    />
                    <v-select
                        :items="filteredOffices"
                        :placeholder="$t('message.company')"
                        class="filter-width-120 ml-2"
                        autocomplete="off"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Office.id"
                        item-text="Office.code"
                        solo
                        v-model="filterValues.office"
                        @change="filterLocalSalesInvoices()"
                    />
                    <v-autocomplete
                        :items="localSalesInvoiceBuyers"
                        :loading="loading.filter.buyers"
                        :placeholder="$t('message.buyers')"
                        class="filter-width-200 ml-2"
                        autocomplete="off"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Customer.id"
                        item-text="Customer.title"
                        solo
                        v-model="filterValues.buyer"
                        @change="filterLocalSalesInvoices()"
                    />
                    <div class="col-12 height-10px pa-0" v-if="$vuetify.breakpoint.mdAndDown"></div>
                    <v-autocomplete
                        :items="allSalesColleagues"
                        :placeholder="$t('message.marketing')"
                        :class="'filter-width-200' + ($vuetify.breakpoint.lgAndUp ? ' ml-2' : '')"
                        autocomplete="off"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Salesperson.id"
                        item-text="Salesperson.name"
                        solo
                        v-model="filterValues.salesColleague"
                        @change="filterLocalSalesInvoices()"
                    />
                    <v-select
                        :items="years"
                        :placeholder="$t('message.year')"
                        class="filter-width-120 ml-2"
                        dense
                        hide-details="auto"
                        item-value="value"
                        item-text="text"
                        solo
                        v-model="filterValues.year"
                        @change="filterLocalSalesInvoices()"
                    />
                    <v-select
                        :items="months"
                        :placeholder="$t('message.month')"
                        :disabled="filterValues.year == null"
                        class="filter-width-120 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Month.id"
                        item-text="Month.text"
                        solo
                        v-model="filterValues.month"
                        @change="filterLocalSalesInvoices()"
                    ></v-select>
                    <v-btn
                        :loading="loading.clear"
                        class="ml-2 px-2"
                        @click="clearFilters"
                    >{{ $t('message.clear') }}</v-btn>
                    <v-spacer></v-spacer>
                    <ExportTableJson
                        :export-conditions="exportConditions"
                        :export-data="LocalSalesInvoices"
                        :export-fields="headers"
                        :export-source="'localsales'"
                        class="ml-3 mb-3"
                    />
                </div>
            </v-row>
            <v-row no-gutters>
                <v-col class="d-flex flex-row align-center">
                    <div class="font-sm">
                        <v-icon small color="green lighten-2">fiber_manual_record</v-icon>{{ $t('message.posted') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="red lighten-2">fiber_manual_record</v-icon>{{ $t('message.unposted') }}
                    </div>
                </v-col>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.invoices"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: itemsPerPageOptions,
                showFirstLastPage: true
            }"
            :headers="headers"
            :height="totalLocalSalesInvoices < 2 ? '150px' : null"
            :hide-default-header="hideDefaultHeader"
            :hide-default-footer="hideDefaultFooter"
            :items="LocalSalesInvoices"
            :options="tableOptions"
            :server-items-length="totalLocalSalesInvoices"
            calculate-widths
            class="mt-3 appic-table-light specification-table"
            dense
            id="warehouseInvoicesTable"
            item-key="LocalSalesInvoice.id"
            @update:options="updateDataTable"
        >
            <template v-slot:item.LocalSalesInvoice.title="{ item }">
                <span class="font-weight-bold ml-0">{{ item.LocalSalesInvoice.title }}</span>
            </template>
            <template v-slot:item.LocalSalesInvoice.SalesOrder.title="{ item }">
                <span class="text-no-wrap">{{ item.LocalSalesInvoice.SalesOrder.title }}</span>
            </template>
            <template v-slot:item.LocalSalesInvoice.localsaledate="{ item }">
                {{ item.LocalSalesInvoice.localsaledate != '0000-00-00' ? formatDate(item.LocalSalesInvoice.localsaledate) : '' }}
            </template>
            <template v-slot:item.LocalSalesInvoice.Customer.title="{ item }">
                {{ item.LocalSalesInvoice.Customer.otsname != '' ? item.LocalSalesInvoice.Customer.otsname : item.LocalSalesInvoice.Customer.title }}
            </template>
            <template v-slot:item.LocalSalesInvoice.amount="{ item }">
                <div class="text-left text-no-wrap">{{ invoiceAmount(item) }}</div>
            </template>
            <template v-slot:item.LocalSalesInvoice.Paymentterm.title="{ item }">
                {{ item.LocalSalesInvoice.Paymentterm.title }}
            </template>
            <template v-slot:item.LocalSalesInvoice.Payment.fully_paid_on="{ item }">
                {{ item.LocalSalesInvoice.Payment.fully_paid_on != '0000-00-00' && item.LocalSalesInvoice.Payment.fully_paid_on != null  ? formatDate(item.LocalSalesInvoice.Payment.fully_paid_on) : $t('message.na') }}
            </template>
            <template v-slot:item.LocalSalesInvoice.is_posted="{ item }">
                <div class="text-center">
                    <v-icon small color="green lighten-2" v-if="item.LocalSalesInvoice.is_posted == 1">fiber_manual_record</v-icon>
                    <v-icon small color="green lighten-2" v-else>fiber_manual_record</v-icon>
                </div>
            </template>
            <template v-slot:item.LocalSalesInvoice.posted="{ item }">
                {{ item.LocalSalesInvoice.posted != '0000-00-00' && item.LocalSalesInvoice.posted != null  ? formatDate(item.LocalSalesInvoice.posted) : $t('message.na') }}
            </template>
            <template v-slot:item.LocalSalesInvoice.lhdn_validated="{ item }">
                {{ item.LocalSalesInvoice.lhdn_validated != '0000-00-00' && item.LocalSalesInvoice.lhdn_validated != null  ? formatDate(item.LocalSalesInvoice.lhdn_validated) : $t('message.na') }}
            </template>
            <template v-slot:item.LocalSalesInvoice.id="{ item }">
                <div class="text-center">
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.moreActions') }}</span>
                            </v-tooltip>
                        </template>
                        <v-list dense>
                            <v-list-item class="font-sm" @click="updateLocalSalesInvoice(item.LocalSalesInvoice.id)" v-if="$can('update','LocalSale')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateInvoice') }}</v-list-item>
                            <v-list-item class="font-sm" @click="postLocalSalesInvoice(item.LocalSalesInvoice.id)" v-if="false"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.postInvoiceGp') }}</v-list-item>
                            <v-list-item class="font-sm" @click="cancelLocalSalesInvoice(item.LocalSalesInvoice.id, item.LocalSalesInvoice.title)"  v-if="$can('delete','LocalSale')"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.cancelInvoice') }}</v-list-item>
                            <PrintLocalSaleButtons
                                :local-sale-id="item.LocalSalesInvoice.id"
                                :office-id="item.LocalSalesInvoice.office_id"
                                :update-mode="true"
                                :list-mode="true"
                                v-if="$can('read','LocalSale')"
                            />
                        </v-list>
                    </v-menu>
                </div>
            </template>
            <template v-slot:body.append="{items}">
                <tr class="pb-1">
                    <td colspan="7" class="success lighten-2 text-start"></td>
                    <td class="success lighten-2 text-start">
                        <div class="py-1 font-weight-bold text-right" v-for="(amount, index) in totalAmounts">
                            {{ $t('message.total') + ":" }}
                        </div>
                    </td>
                    <td class="success lighten-2 text-start">
                        <div class="py-1 font-weight-bold text-no-wrap" v-for="(amount, index) in totalAmounts">
                            {{ index + ' ' + formatThisNumber(amount,currencyFormatPrecision(index)) }}
                        </div>
                    </td>
                    <td colspan="4" class="success lighten-2 text-start"></td>
                </tr>
            </template>
        </v-data-table>
        <SimpleAlert
            :alert_message.sync="dialogs.error_message"
            :dialog.sync="dialogs.error"
            @dialog-closed="dialogClosed"
        />
    </div>
</template>

<script>
    import {formatDate} from "Helpers/helpers";
    import {numberFormat} from "../../helpers/helpers";
    import {mapGetters, mapActions} from "vuex";
    import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    import { v4 as uuidv4 } from 'uuid';

    const SimpleAlert = () => import("Components/Appic/SimpleAlert");
    const ExportTableJson = () => import("Components/Appic/ExportTableJson");
    const PrintLocalSaleButtons = () => import("Components/Appic/Printing/PrintLocalSaleButtons");

    export default {
        name: "WarehouseInvoicesListingNew",
        components: {PrintLocalSaleButtons, ExportTableJson, SimpleAlert },
        data() {
            return {
                currentFilter: null,
                currentSearch: null,
                dialogs: {
                    error:  false,
                    error_message: ""
                },
                exportConditions: {},
                filterOptions: {
                    status: {
                        Status: {
                            id: 1,
                            title: 'open'
                        }
                    },
                    year: null
                },
                filterValues: {
                    buyer: null,
                    month: null,
                    office: null,
                    salesColleague: null,
                    status: null,
                    year: null
                },
                hideDefaultHeader: false,
                hideDefaultFooter: false,
                loading: {
                    clear: false,
                    filter: {
                        buyers: false
                    },
                    search: false,
                    invoices: false
                },
                searchField: 'LocalSalesInvoice.title',
                searching: false,
                searchTerm: null,
                searchValues: {
                    salesOrder: null,
                    invoice: null
                },
                tableOptions: {},
            }
        },
        computed: {
            ...mapMultiRowFields('localsalesinvoice', {
                LocalSalesInvoices: 'current.LocalSalesInvoices'
            }),
            ...mapFields('localsalesinvoice', {
                localSalesInvoiceBuyers: 'current.localSalesInvoiceBuyers',
                totalAmounts: 'current.totalAmounts',
                totalLocalSalesInvoices: 'current.totalLocalSalesInvoices',
            }),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
            ...mapGetters('country', {
                allCountries: 'allCountries'
            }),
            ...mapGetters('currency',{
                allCurrencies: 'allCurrencies'
            }),
            ...mapGetters('salescolleague', {
                allSalesColleagues: 'allSalesColleagues'
            }),
            ...mapGetters([
                'currencies',
                'months',
                'offices'
            ]),
            console: () => console,
            defaultItemsPerPage() {
                if(window.screen.height >= 800) return 15
                return 10
            },
            filteredOffices () {
                return this.offices.filter( o => o.Office.has_warehouse === true)
            },
            formatDate: () => formatDate,
            headers() {
                return [
                    {
                        id: 0,
                        text: this.$t('message.actions'),
                        value: 'LocalSalesInvoice.id',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                        sortable: false,
                        searchable: false
                    },
                    {
                        id: 1,
                        text: this.$t('message.date'),
                        value: 'LocalSalesInvoice.localsaledate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true,
                        searchable: false
                    },
                    {
                        id: 2,
                        text: this.$t('message.invoiceNumber'),
                        value: 'LocalSalesInvoice.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false,
                        searchable: false
                    },
                    {
                        id: 3,
                        text: this.$t('message.status'),
                        value: 'LocalSalesInvoice.is_posted',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                        sortable: false,
                        searchable: false
                    },
                    {
                        id: 4,
                        text: this.$t('message.salesOrder'),
                        value: 'LocalSalesInvoice.SalesOrder.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false,
                        searchable: false
                    },
                    {
                        id: 5,
                        text: this.$t('message.company'),
                        value: 'LocalSalesInvoice.Office.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false,
                        searchable: false
                    },
                    {
                        id: 6,
                        text: this.$t('message.buyer'),
                        value: 'LocalSalesInvoice.Customer.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false,
                        searchable: false
                    },
                    {
                        id: 7,
                        text: this.$t('message.salesColleague'),
                        value: 'LocalSalesInvoice.Salescontact.name',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false,
                        searchable: false
                    },
                    {
                        id: 8,
                        text: this.$t('message.value'),
                        value: 'LocalSalesInvoice.amount',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false,
                        searchable: false
                    },
                    {
                        id: 9,
                        text: this.$t('message.paymentStatus'),
                        value: 'LocalSalesInvoice.Paymentterm.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false,
                        searchable: false
                    },
                    {
                        id: 10,
                        text: this.$t('message.paymentDate'),
                        value: 'LocalSalesInvoice.Payment.fully_paid_on',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false,
                        searchable: false
                    },
                    {
                        id: 11,
                        text: this.$t('message.lhdnDateValidated'),
                        value: 'LocalSalesInvoice.Lhdn.validated',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false,
                        searchable: false
                    },
                    {
                        id: 12,
                        text: this.$t('message.xeroDatePosted'),
                        value: 'LocalSalesInvoice.posted',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false,
                        searchable: false
                    }
                ]
            },
            itemsPerPageOptions() {
                let options = [10,20,50,-1];
                if(window.screen.height >= 800){
                    options = [15,30,50,-1];
                }
                return options;
            },
            searchFields() {
                return this.headers.filter((header)=>header.searchable === true)
            },
            years() {
                let years = []
                let dt = new Date()
                let startYear = dt.getFullYear()
                let endYear = 2017
                for(let i = startYear; i >= endYear; i--){
                    years.push({value: i, text: i.toString()})
                }
                return years
            }
        },
        methods: {
            ...mapActions('currency', {
                getAllCurrencies: 'getAllCurrencies'
            }),
            ...mapActions('localsalesinvoice',{
                cancelLocalSalesInvoiceById: 'cancelLocalSalesInvoiceById',
                getAllLocalSalesInvoices: 'getAllLocalSalesInvoices',
                getAllLocalSalesInvoiceBuyers: 'getAllLocalSalesInvoiceBuyers',
                postLocalSalesInvoiceById: 'postLocalSalesInvoiceById',
                searchAllLocalSalesInvoices: 'searchAllLocalSalesInvoices'
            }),
            async cancelLocalSalesInvoice(val, title){
                if(await this.$root.$confirm(this.$t('message.cancelInvoice') + ' ' + title, this.$t('message.confirmations.continueInvoiceCancelAction'), {color: 'orange'})){
                    this.cancelLocalSalesInvoiceById(val)
                        .then((response) => {
                            if(response.status == 'success'){
                                this.$toast.success(this.$t('message.successes.invoiceDeleted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loadLocalSalesInvoices()
                            } else {
                                this.$toast.error(this.$t('message.errors.invoiceNotDeleted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(()=>{
                            this.$toast.error(this.$t('message.errors.invoiceNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                }
            },
            clearFilters() {
                this.loading.clear = true
                this.filterValues.buyer = null
                this.filterValues.month = null
                this.filterValues.office = null
                this.filterValues.salesColleague = null
                this.filterValues.status = 0
                this.filterValues.year = null
                this.searchValues.salesOrder = null
                this.searchValues.invoice = null
                this.tableOptions.page = 1
                this.tableOptions.sortBy[0] = 'Localsale.localsaledate'
                this.tableOptions.sortDesc[0] = true
                this.loading.clear = false
                this.filterLocalSalesInvoices()
            },
            currency(currency_id) {
                return this.allCurrencies.find((currency) => currency.Currency.id == currency_id)?.Currency?.code
            },
            currencyFormatPrecision (currency) {
                if(isNaN(currency)){
                    return this.allCurrencies.find((c) => c.Currency.code == currency)?.Currency?.formatPrecision
                } else {
                    return this.allCurrencies.find((c) => c.Currency.id == currency)?.Currency?.formatPrecision
                }
            },
            dialogClosed() {
                this.dialogs.error = false;
                this.dialogs.error_message = ''
            },
            filterLocalSalesInvoices() {
                this.searchValues.salesOrder = null
                this.searchValues.invoice = null
                let noValues = true
                const keys = Object.keys(this.filterValues)
                keys.forEach((key, index) => {
                    if(this.filterValues[key] != null) noValues = false
                })
                if(noValues == false) {
                    let conditions = []
                    if (this.filterValues.buyer != null) conditions.push({
                        field: 'localsales.customer_id',
                        value: this.filterValues.buyer
                    })
                    if (this.filterValues.month != null) conditions.push({
                        field: 'localsales.month',
                        value: this.filterValues.month
                    })
                    if (this.filterValues.office != null) conditions.push({
                        field: 'offices.id',
                        value: this.filterValues.office
                    })
                    if (this.filterValues.salesColleague != null) conditions.push({
                        field: 'localsales.salescontact_id',
                        value: this.filterValues.salesColleague
                    })
                    if (this.filterValues.year != null) conditions.push({
                        field: 'localsales.year',
                        value: this.filterValues.year
                    })

                    if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage

                    if (_.isEqual(this.currentFilter, this.filterValues) === false) {
                        this.tableOptions.page = 1
                        this.currentFilter = _.cloneDeep(this.filterValues)
                    }

                    if(_.has(this.tableOptions, 'sortBy') == false) {
                        this.tableOptions['sortBy'] = ['LocalSale.localsaledate']
                    }

                    if(this.tableOptions.sortBy.length == 0){
                        this.tableOptions['sortBy'] = ['LocalSale.localsaledate']
                    }
                    if(_.has(this.tableOptions, 'sortDesc') == false){
                        this.tableOptions['sortDesc'] = [false]
                        if(this.tableOptions.sortBy[0] == 'LocalSale.localsaledate') {
                            this.tableOptions['sortDesc'] = [true]
                        }
                    }
                    if(this.tableOptions.sortBy[0] == 'LocalSale.localsaledate' && this.tableOptions.sortDesc.length == 0){
                        this.tableOptions['sortDesc'] = [true]
                    }

                    let order = [{field: this.tableOptions.sortBy[0], direction: this.tableOptions.sortDesc[0] ? 'DESC' : 'ASC'}]

                    let payload = {
                        tableOptions: this.tableOptions,
                        conditions: conditions,
                        order: order
                    }

                    this.hideDefaultFooter = false
                    this.loading.invoices = true;
                    this.searchAllLocalSalesInvoices(payload)
                        .then(() => {
                            this.loading.invoices = false;
                        })
                        .catch(() => {
                            this.loading.invoices = false;
                        })
                        .finally(() => {
                            this.loading.invoices = false;
                        })
                } else {
                    this.loadLocalSalesInvoices()
                }
            },
            formatThisNumber (value,format) {
                return numberFormat(value,format)
            },
            invoiceAmount(item){
                return this.currency(item.LocalSalesInvoice.currency_id) + ' ' + this.formatThisNumber((item.LocalSalesInvoice.Total.total),this.currencyFormatPrecision(item.LocalSalesInvoice.currency_id))
            },
            loadLocalSalesInvoices () {
                this.hideDefaultFooter = false
                this.loading.invoices = true;
                let payload = {
                    tableOptions: this.tableOptions,
                    // filterOptions: this.filterOptions
                }
                this.getAllLocalSalesInvoices(payload)
                    .then(()=>{
                        this.loading.invoices = false;
                    })
                    .catch(()=>{
                        this.loading.invoices = false;
                    })
                    .finally(()=>{
                        this.loading.invoices = false;
                    })
            },
            async postLocalSalesInvoice(){
                if(await this.$root.$confirm(this.$t('message.postInvoice'), this.$t('message.confirmations.postInvoiceCancelAction'), {color: 'orange'})){
                    this.postInvoiceById(val).then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.invoicePosted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loadLocalSalesInvoices()
                        } else {
                            this.$toast.error(this.$t('message.errors.invoiceNotPosted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                }
            },
            resetSearch (filter = null) {
                // this.hideDefaultFooter = false
                // this.loading.invoices = false
                // this.searchField = 'LocalSalesInvoice.title';
                // this.searchTerm = null;
                // this.tableOptions.itemsPerPage = 10
                // this.filterOptions = {
                //     status: {
                //         Status: {
                //             id: 1,
                //             title: 'open'
                //         }
                //     },
                //     year: new Date().getFullYear()
                // };
                // this.filterOptions.year = new Date().getFullYear()
                // this.searchValues.salesOrder = null
                // this.searchValues.invoice = null
                this.searching = false
                this.hideDefaultFooter = false
                this.filterValues.status = 0
                this.tableOptions.page = 1
                if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage
                switch(filter){
                    case 'invoice':
                    case 'salesOrder':
                        this.searchValues[filter] = []
                        break
                }
                this.tableOptions.sortBy = []
                this.tableOptions.sortDesc = []
                this.filterLocalSalesInvoices()
            },
            searchLocalSalesInvoices (field) {
                if(this.searchValues[field] == null){
                    this.dialogs.error = true
                    this.dialogs.error_message = this.$t('message.errors.noSearchColumnAndSearchTerm')
                } else {
                    this.searching = true
                    this.filterValues.buyer = null
                    this.filterValues.marketing = null
                    this.filterValues.month = null
                    this.filterValues.office = null
                    this.filterValues.status = null
                    this.filterValues.year = null

                    const keys = Object.keys(this.searchValues)
                    keys.forEach((key, index) => {
                        if(key != field) this.searchValues[key] = null
                    })

                    let fieldName = null
                    let sortFieldName = null
                    switch(field){
                        case 'salesOrder':
                            fieldName = 'LocalSalesOrder.title'
                            sortFieldName = 'LocalSalesInvoice.localsaledate'
                            break
                        case 'invoice':
                            fieldName = 'LocalSale.title'
                            sortFieldName = 'LocalSalesInvoice.localsaledate'
                            break
                    }
                    this.loading.invoices = true
                    let payload = {}
                    let order = []

                    if (_.isEqual(this.currentSearch, this.searchValues) === false) {
                        //new search = new sort
                        order.push({field: fieldName, direction: field == 'salesOrder' || field == 'invoice' ? 'ASC' : 'DESC'})
                        this.tableOptions.sortBy[0] = sortFieldName
                        this.tableOptions.sortDesc[0] = field == 'salesOrder' || field == 'invoice' ? false : true
                        this.currentSearch = _.cloneDeep(this.searchValues)
                        //new search new page
                        this.tableOptions.page = 1
                    } else {
                        //same search new sort
                        if(this.tableOptions.sortBy.length == 0){
                            this.tableOptions.sortBy[0] = sortFieldName
                        }
                        order.push({field: this.tableOptions.sortBy[0], direction: this.tableOptions.sortDesc[0] ? 'DESC' : 'ASC'})
                    }

                    if(fieldName != null) {
                        payload = {
                            tableOptions: this.tableOptions,
                            conditions: [
                                {
                                    field: fieldName,
                                    value: this.searchValues[field]
                                }
                            ],
                            order: order
                        }
                    } else {
                        payload = {
                            tableOptions: this.tableOptions,
                            conditions: [],
                            order: order
                        }
                    }

                    this.searchAllLocalSalesInvoices(payload)
                        .then(() => {
                            this.loading.search = false;
                            this.loading.invoices = false;
                        })
                        .catch(()=>{
                            this.loading.search = false;
                            this.loading.invoices = false;
                        })
                        .finally(()=>{
                            this.loading.search = false;
                            this.loading.invoices = false;
                        })
                }
            },
            updateDataTable(options) {
                this.tableOptions = options
                if(this.searching === true)  {
                    // check which field is not empty
                    let field = null
                    if(this.searchValues.contract != null) field = 'contract'
                    if(this.searchValues.invoice != null) field = 'invoice'
                    this.searchLo(field)
                } else {
                    this.filterLocalSalesInvoices()
                }
            },
            updateLocalSalesInvoice ( val ) {
                let tab = window.open('/v1/localsales/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            viewPdf ( localSaleId, docType ) {
                let prefix = 'INV'
                if(docType == 'ro') prefix = 'RO'
                if(docType == 'do') prefix = 'DO'
                let document = encodeURIComponent(prefix + " " + this.LocalSalesInvoices.find( l => l.LocalSalesInvoice.id == localSaleId )?.LocalSalesInvoice.title)
                let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/localsales/print/'
                    + localSaleId
                    + '/'
                    + docType
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank");
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
        },
        watch: {
            // filterOptions: {
            //     handler(){
            //         if(!this.loading.search) {
            //             this.tableOptions.page = 1
            //             this.loadLocalSalesInvoices()
            //         }
            //     },
            //     deep: true
            // },
            // tableOptions: {
            //     handler(){
            //         this.loadLocalSalesInvoices()
            //     },
            //     deep: true
            // },
        },
        created() {
            this.searchValues.contract = null
            this.searchValues.invoice = null
            this.filterValues.buyer = null
            this.filterValues.month = null
            this.filterValues.office = null
            this.filterValues.salesColleague = null
            this.filterValues.status = 0
            this.filterValues.year = null

            if(this.allCurrencies.length == 0) this.getAllCurrencies()

            if(this.filterValues.status == 0){
                this.filterLocalSalesInvoices()
            } else {
                this.loadLocalSalesInvoices()
            }
            this.getAllLocalSalesInvoiceBuyers()
        }
        // created() {
        //     this.loading.filter.buyers = true
        //     this.getAllLocalSalesInvoiceBuyers()
        //         .then(()=>{
        //             this.loading.filter.buyers = false
        //             return 'ok'
        //         })
        //         .then(this.loadLocalSalesInvoices())
        // }
    }
</script>

<style>
.filter-width-120 {
    width: 120px !important;
    max-width: 120px !important;
}
.filter-width-200 {
    width: 185px !important;
    max-width: 185px !important;
}
.col-0-75 {
    flex: 0 0 6.25%;
    max-width: 6.25%;
}
.col-1-5 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
}
.col-3-5 {
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
    vertical-align: middle !important;
}
.height-10px {
    height: 10px;
}
</style>